<script setup lang="ts">
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const navigationController = useModal();
const searchController = useModal();
const accountController = useModal();
const cartController = useModal();

const { beforeEach } = useRouter();
beforeEach(() => {
  navigationController.close();
  searchController.close();
  accountController.close();
  cartController.close();
});

// Close other modals if one is opened
function useExclusiveModal(controllers: ReturnType<typeof useModal>[]) {
  controllers.forEach((controller) => {
    watch(controller.isOpen, (open) => {
      if (!open) return;

      controllers.forEach((other) => {
        if (controller == other) return;
        other.close();
      });
    });
  });
}

useExclusiveModal([
  navigationController,
  searchController,
  accountController,
  cartController,
]);

const header = ref<HTMLHeadElement>();
const { top: headerOffset } = useElementBounding(header);
provide("headerOffset", headerOffset);
</script>

<template>
  <LayoutTopBar />
  <header
    id="header"
    ref="header"
    class="shadow sticky top-0 bg-scheppach-shades-0 text-scheppach-primary-700 z-1000"
    @click="navigationController.close"
  >
    <div class="h-14 xl:h-22.5 custom-container items-center flex">
      <!-- Logo Deskt -->
      <a :href="formatLink('/')" class="mr-20 hidden xl:block">
        <img src="/logo-scheppach.svg" alt="logo" class="h-10 xl:h-14.5" />
      </a>

      <!-- Navbar + Searchbar -->
      <div
        class="flex flex-grow flex-basis-none justify-start items-center gap-2 sm:gap-5 ml--2 xl:ml-0 h-full"
      >
        <LayoutNavigation :controller="navigationController" />
        <LayoutSearchMobile :controller="searchController" />
      </div>

      <!-- Logo Mobile -->
      <a :href="formatLink('/')" class="mx-2 xl:mx-14.5 block xl:hidden">
        <img src="/logo-scheppach.svg" alt="logo" class="h-10 xl:h-14.5" />
      </a>

      <!-- Actions -->
      <div
        class="flex-grow flex-basis-none flex justify-end items-center gap-5 xl:mr-0 c-scheppach-primary-700"
      >
        <LayoutSearchDesktop class="hidden xl:block" />
        <div class="flex gap-2 items-center">
          <CheckoutSideCart :controller="cartController" />
          <AccountSidebar :controller="accountController" />
          <ProductCompareSidebar />
        </div>
      </div>
    </div>
  </header>
</template>
